<template>
  <div>
    <CheckboxISP />
  </div>
</template>

<script>
import CheckboxISP from "@/modules/UiKit/components/Checkbox/CheckboxISP";
export default {
  name: "CheckboxPage",
  mixins: [],
  props: {},
  components: { CheckboxISP },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
</style>
